<template>
  <div class="bg-gray-300">
    <div class="max-w-5xl mx-auto min-h-screen">
      <h2 class="text-center">PageCustomSupplyLLC.com QB Connection</h2>
      <div>
        <p>
          This App is only designed to hook this website up with Quickbooks Online. No other use has
          been designed.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped></style>
